import React, { useEffect, useState } from 'react';
import Bottom from 'src/components/Bottom';
import { ProjectPosts } from 'src/components/posts/ProjectPosts';
import { Fade } from 'react-reveal';
import Manifestitions from 'src/components/projects/Manifestitions';


function InnerProjects() {
  const [data, setData] = useState([])

  const url = window.location.href;
  const array = url.split('/');
  const key = array[array.length - 1];

  useEffect(() => {
    const filteredData = ProjectPosts.filter(post => post.key === key);
    setData(filteredData[0]?.posts || null);
  }, [])

  // console.log(data)

  return (
    <div className="max-w-[1920px] block mx-auto overflow-x-hidden">
      <div id="aboutUsPage" >
        <Manifestitions posts={data} hasPartners={false} />
      </div>
    </div>
  );
}

export default InnerProjects;
