import React, { useState, useEffect } from 'react';
// import EventGalleryModal from "../modal/EventGalleryModal";
import EventGalleryModal from "src/components/modal/EventGalleryModal";
import { ProjectPosts, ProjectPostInner } from 'src/components/posts/ProjectPosts';
import Vimeo from 'src/components/vimeo';
import ReactHtmlParser from "react-html-parser";
import { Carousel } from 'react-responsive-carousel';
import Error404 from "src/pages/404"
import Media from './Media';
import GoBack from 'src/components/nav/GoBack';
import { Fade } from "react-awesome-reveal";
import { useNavigate } from 'react-router-dom'
import InnerPage2 from './InnerPage2';
import InnerPageVideo from './InnerPageVideo';

const legendaryBrands = [
  {
    title: "BURNING MAN",
    description: "Final burning effigy of the \nBurning Man",
    long_description: `
        <p class="mb-5">From a small bonfire ritual, they created a grand ceremony that culminates in burning a giant effigy. The Burning Man generated a cult following by harnessing art, self-expression, and inclusive values, transcending into a yearly event that is attended by thousands.</p>
        <p>The National Public Radio in America famously said, "Once considered an underground gathering for bohemians and free spirits of all stripes, Burning Man has since evolved into a destination for social media influencers, celebrities and the Silicon Valley elite.</p>`,
    image: "/img/landing/Burning-Man-min.png",
  },
  {
    title: "APPLE",
    description: "The seamless Apple Store \nbuying experience",
    long_description: `<p class="mb-5">Apple is the largest technology company by revenue and is ranked as one of the world’s most valuable brands. A pillar in the industry, they generated a high net promoter score (NPS) by continually upholding their “Think Different” values throughout the years. They continue to create innovations with simplicity and convenience of users in mind.</p>
        <p>This cultural phenomenon has created a customer base that is rabidly loyal to their products and services.</p>`,
    image: "/img/landing/Apple-min.png",
  },
  {
    title: "STARBUCKS",
    description:
      "personalized the coffee cup by creating a ritual of asking consumers for their names and order",
    long_description: `
        <p>Starbucks personalized the coffee cup by creating a ritual of asking consumers for their names and order. This simple ritual of writing the consumer's preferred drink on the cup with their name became a global staple that everybody remembered.</p>`,
    image: "/img/landing/Starbucks-min.jpg",
  },
  {
    title: "FORWARD",
    description:
      "Doctor's offices of the future \nelevate the healthcare experience",
    long_description: `<p class="mb-5">Reinventing how healthcare is given to people is an unenviable task, yet Forward is here to do it. Dedicated to patient’s long-term health, this company made a giant leap in healthcare by providing state-of-the-art medical products to the world… for free.</p>
        <p>With a dose of Apple, Netflix, and AI in their system, they are currently servicing more than a billion people, and the rest of the industry is playing catch-up to them.</p>`,
    image: "/img/landing/Forward-min.png",
  },
];

function InnerPage() {
  const [post, getPost] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});

  const url = window.location.href;
  const array = url.split('/');
  const key = array[array.length - 1];

  useEffect(() => {

    try {
      getPost(ProjectPostInner.find(post => post.key === key) || null);
    } catch (err) {
      console.log(err)
    }

  }, [])

  if (post === null)
    return <Error404 />;

  if (post.type === 'video')
    return <InnerPageVideo post={post} />;

  if (post.type !== 'carousel' || post.management == 'projects')
    return <InnerPage2 />;

  const handleClick = (i) => {
    setShowModal(true);
    setSelected(i);
  };

  const GalleryItems = ({ data: post }) => {
    var indents = [];
    for (let i = 1; i <= post.src; i++) {
      indents.push(
        <div
          onClick={() => handleClick(i)}
          className="__tile flex items-center justify-center cursor-pointer max-w-[300px]"
          key={i}
        >
          <div className="rounded-lg overflow-hidden p-2 cursor-pointer">
            <img src={`${post.gallerySrc}thumbs/_${i}.jpg`} alt={`Banner`} className='' />
          </div>
        </div>
      )
    }
    return (indents);
  }

  return (
    <section
      id="manifestation"
      className="relative bg-[#FFDEC1] md:py-[8rem] py-4 overflow-hidden"
    >
      <div className="upper-left-star w-[100%] h-[150px]">
        <div className="relative ">
          <Fade
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="left"
          >
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7880-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7878-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7879-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="upper-right-star w-[100%] h-[150px]">
        <div className="relative ">
          <Fade
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="right"
          >
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7881-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7882-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7883-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div
        className="container mx-auto flex justify-center items-center flex-col px-5"
        id="project_header"
      >
        {!!post.headline &&
          <div className="md:max-w-[50%] w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] md:mt-0 mt-[80px]">
            <h3 className="title md:text-3xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
              <span>{post.headline}</span>
            </h3>
          </div>
        }
        {!!post.subheadline &&
          <div className="sm:text-lg text-md text-[#9C1212] text-center mb-16">
            {post.subheadline}
          </div>
        }
      </div>
      <div className={`max-w-[1920px] block mx-auto`}>
        <div
          id="projects"
          // className="max-w-[992px] grid md:grid-cols-3 mx-auto md:gap-x-8 gap-x-7 md:gap-y-8 gap-y-4"
          className="max-w-[992px] flex flex-wrap justify-center md:gap-x-8 gap-x-4 md:gap-y-8 gap-y-4 mx-auto"
        >
          <GalleryItems data={post} />

        </div>

        <div className='mt-24'>
          <div>
            <GoBack />
          </div>
        </div>
      </div>

      {showModal && (
        <EventGalleryModal
          show={showModal}
          close={() => setShowModal(false)}
          post={post}
          selected={selected}
        />
      )}
    </section>
  );
}

export default InnerPage;
