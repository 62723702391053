import React, { useState, useEffect } from 'react';
import { ProjectPosts } from 'src/components/posts/ProjectPosts';
import Vimeo from 'src/components/vimeo';
import ReactHtmlParser from "react-html-parser";
import { Carousel } from 'react-responsive-carousel';
import Error404 from "src/pages/404"
function Project() {
  const [post, getPost] = useState({});

  const url = window.location.href;
  const array = url.split('/');
  const id = array[array.length - 1];

  useEffect(() => {

    try {
      getPost(ProjectPosts.find(post => post.id === id) || null);
    } catch (err) {
      console.log(err)
    }

  }, [])

  if (post === null)
    return <Error404 />;

  const CarouselItems = () => {
    var indents = [];
    for (let i = 1; i <= post.src; i++) {
      indents.push(
        <div key={i}>
          <img src={`/img/projects/${post.id}/_${i}.jpg`} alt={`${post.title} Banner`} />
        </div>
      )
    }
    return (
      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        stopOnHover={true}
        autoPlay={true}
        infiniteLoop={true}
        interval={2000}
        emulateTouch={true}
        className="overflow-hidden"
      >
        {indents}
      </Carousel>
    );
  }

  return (
    <div className="max-w-[1920px] block mx-auto">
      <div id="projects" >
        <section
          id="titleSection"
          className="relative bg-[#FFDEC1] md:py-[8rem] py-4"
        >
          <div className="container mx-auto flex justify-center items-center flex-col px-5">
            <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-16">
              <h3 className="title md:text-3xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                <span>{ReactHtmlParser(post.title)}</span>
              </h3>
            </div>
            <div className="sm:text-lg text-md text-[#9C1212] text-center mb-16">
              {ReactHtmlParser(post.subtitle)}
            </div>
          </div>

          <div className="mx-auto bg-[#000000] md:mb-16">
            <div className="container- mx-auto max-w-[1080px]-">
              <div className="block overflow-hidden">
                {
                  post.type === 'video' && (
                    <div className="video-container " data-ref="">
                      <Vimeo
                        id="ceVideo"
                        video={post.src}
                        controls={true}
                        loop={true}
                        responsive={true}
                        playsInline={true}
                        muted={false}
                        autoplay={false}
                        quality='720p'
                        className=""
                      />
                    </div>
                  )
                }
                {
                  post.type === 'carousel' && (
                    <CarouselItems />
                  )
                }
              </div>
            </div>
          </div>

          <div className="container mx-auto flex justify-center items-center flex-col max-w-[1080px] px-5">
            <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-10 mb-10">
              <h3 className="md:text-2xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                <span>{ReactHtmlParser(post.headline)}</span>
              </h3>
              <h4 className="md:text-xl text-lg text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                <span>{ReactHtmlParser(post.subheadline)}</span>
              </h4>
            </div>
            <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-16">
              {ReactHtmlParser(post.description)}
            </div>
            <a
              className={`min-w-[100px] __button button-2 z-10 hover:text-[#FFFFFF] hover:bg-[#9C1212] ease-in-out duration-300 flex items-center justify-center text-white py-2 lg:px-10 px-2 bg-primary rounded-xl md:text-xl text-sm text-light mx-auto`}
              href="/projects"
            >
              <span>Back</span>
            </a>
          </div>
        </section>
      </div>
    </div>


  );
}

export default Project;
