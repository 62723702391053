import React from "react";
import {
    FaRegEnvelope,
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
} from "react-icons/fa";

export default function Footer({ modal }) {
    // Function will execute on click of button
    const onPDFDownload = () => {
        // using Java Script method to get PDF file
        fetch("Accessibility Statement for Tagline 360.pdf").then(
            (response) => {
                response.blob().then((blob) => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    // let alink = document.createElement('a');
                    // alink.href = fileURL;
                    // alink.download = 'Accessibility Statement for Tagline 360.pdf';
                    // alink.click();
                    //Open the URL on new Window
                    const pdfWindow = window.open();
                    pdfWindow.location.href = fileURL;
                });
            }
        );
    };

    const logo = [
        {
            id: "1",
            img: "/img/footer/1.png",
            src: "https://frowfw.com/",
        },
        {
            id: "2",
            img: "/img/footer/2.png",
            src: "https://modelhunt.nyc/",
        },
        {
            id: "3",
            img: "/img/footer/3.png",
            src: "https://www.modelofchoice.com/",
        },
        {
            id: "4",
            img: "/img/footer/4.png",
        },
    ];

    const membership_logo = [
        {
            id: "1",
            img: "/img/footer/member_1.png",
            // src: "https://frowfw.com/",
        },
    ];

    return (
        <>
            <footer className="relative bg-primary">
                <div className="md:p-10 p-5">
                    <div className="flex md:justify-between justify-center items-end h-full">
                        <div className="h-full w-full">
                            <div className="brand sm:px-0 px-[1rem]">
                                <img
                                    src="/img/tagline360-logo-light-min.png"
                                    width={150}
                                    alt=""
                                    className="max-w-full md:mx-0 mx-auto mb-3"
                                />
                            </div>
                            <div className="md:text-left text-center mb-5">
                                <div className="md:text-lg text-lg text-white font-bold">
                                    BOOK YOUR BRAND BLUEPRINT SESSION
                                </div>
                                <div className="flex md:justify-normal justify-center gap-3 flex-1 mx-auto pb-1">
                                    <div>
                                        <img
                                            src="/img/hubspot-partner-logo-light.png"
                                            width={100}
                                            alt=""
                                            className="max-w-full md:mx-0 mx-auto block"
                                        />
                                    </div>
                                    <div>
                                        <a href="https://skills-simplified.com/" target="_blank">
                                            <img
                                                src="/img/skill-simplified.png"
                                                width={100}
                                                alt=""
                                                className="max-w-full md:mx-0 mx-auto block"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="md:text-md text-md mt-4 text-white mb-2">
                                    Affiliates / Membership as of 2024
                                </div>
                                <div className="flex gap-2 md:justify-start justify-center items-center">
                                    {logo.map((e, i) => {
                                        return (
                                            <div
                                                id={e.id}
                                                className={`s-item flex justify-center items-center w-12 h-12 rounded-full ${
                                                    e.src
                                                        ? "cursor-pointer"
                                                        : ""
                                                }`}
                                            >
                                                <a
                                                    className="s-icon text-primary text-2xl self-center"
                                                    href={e.src}
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={e.img}
                                                        alt=""
                                                        className="h-full w-full object-cover object-top"
                                                    />
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* <div className="md:text-md text-md mt-4 text-white mb-2">Membership</div>
            <div className="flex gap-2 md:justify-start justify-center items-center">
              {membership_logo.map((e, i) => {
                return (
                  <div
                    id={e.id}
                    className="s-item flex justify-center items-center w-12 h-12 rounded-full"
                  >
                    <a
                      className="s-icon text-primary text-2xl self-center"
                      href={e.src}
                      target="_blank"
                    >
                      <img
                        src={e.img}
                        alt=""
                        className="h-full w-full object-cover object-top"
                      />
                    </a>
                  </div>
                );
              })}
            </div> */}
                            </div>
                            <div className="md:text-right text-center md:absolute top-[70%] right-10 md:hidden block">
                                <div
                                    className="flex gap-2 md:justify-end justify-center items-center"
                                    id="socials"
                                >
                                    <div
                                        className="s-item flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer"
                                        onClick={modal}
                                        tabIndex="0"
                                    >
                                        <div className="s-icon text-primary text-2xl self-center">
                                            <FaRegEnvelope />
                                        </div>
                                    </div>
                                    <div
                                        className="s-item flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer"
                                        onClick={() =>
                                            window.open(
                                                "https://www.facebook.com/Tagline-360-100285849146740/?ref=page_internal",
                                                "_blank"
                                            )
                                        }
                                        tabIndex="0"
                                    >
                                        <div className="s-icon text-primary text-2xl self-center">
                                            <FaFacebookF />
                                        </div>
                                    </div>
                                    <div
                                        className="s-item flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer"
                                        onClick={() =>
                                            window.open(
                                                "https://www.instagram.com/tagline360nyc/",
                                                "_blank"
                                            )
                                        }
                                        tabIndex="0"
                                    >
                                        <div className="s-icon text-primary text-2xl self-center">
                                            <FaInstagram />
                                        </div>
                                    </div>
                                    <div
                                        className="s-item flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer"
                                        onClick={() =>
                                            window.open(
                                                "https://www.linkedin.com/company/tagline-360",
                                                "_blank"
                                            )
                                        }
                                        tabIndex="0"
                                    >
                                        <div className="s-icon text-primary text-2xl self-center">
                                            <FaLinkedinIn />
                                        </div>
                                    </div>
                                </div>
                                <div className="md:text-end text-center">
                                    <div className="md:text-md text-md mt-4 text-white">
                                        New York Office: 260 Madison Avenue, FL
                                        8, NYC 10016 NY
                                    </div>
                                    <div className="md:text-md text-md text-white">
                                        Boston Office: 61 Colborne Rd. 6,
                                        Brighton 02135 MA
                                    </div>
                                    <div className="md:text-md text-md text-white">
                                        California Office: 9245 Laguna Springs
                                        Dr. Suite 200 Elk Grove, 95758 CA
                                    </div>
                                    <div className="md:text-md text-md text-white">
                                        London Office: 160 City Road London EC1V
                                        2NX
                                    </div>
                                    <div className="md:text-md text-md text-white">
                                        Dubai Office: Hor Al Anz Building,
                                        Office 101-24, Hor Al Anz, Dubai UAE
                                    </div>
                                </div>
                            </div>

                            <div
                                className="flex gap-2 md:justify-start justify-center items-center md:mt-7 mt-5"
                                id="accessibilityPolicy"
                            >
                                <button onClick={() => onPDFDownload()}>
                                    <span className="text-white underline">
                                        Accessibility Policy
                                    </span>
                                </button>
                            </div>
                            <div className="md:text-start text-center md:text-md text-md mt-4 text-white md:w-[70%] sm:w-[70%] w-[90%]  align-bottom h-full md:mx-0 mx-auto">
                                <div className="">
                                    Diversity. Equity. Inclusion. Innovation.
                                    <br />
                                    <br />
                                    Tagline 360 is a proud Minority Business
                                    Enterprise committed to equal opportunity
                                    for all. We foster an inclusive, empowering
                                    workplace where diverse perspectives drive
                                    creativity, innovation, and impact.
                                </div>
                            </div>
                        </div>

                        <div className="md:block hidden h-full w-full">
                            <div
                                className="flex gap-2 md:justify-end justify-center items-center"
                                id="socials"
                            >
                                <div
                                    className="s-item flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer"
                                    onClick={modal}
                                    tabIndex="0"
                                >
                                    <div className="s-icon text-primary text-2xl self-center">
                                        <FaRegEnvelope />
                                    </div>
                                </div>
                                <div
                                    className="s-item flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer"
                                    onClick={() =>
                                        window.open(
                                            "https://www.facebook.com/Tagline-360-100285849146740/?ref=page_internal",
                                            "_blank"
                                        )
                                    }
                                    tabIndex="0"
                                >
                                    <div className="s-icon text-primary text-2xl self-center">
                                        <FaFacebookF />
                                    </div>
                                </div>
                                <div
                                    className="s-item flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer"
                                    onClick={() =>
                                        window.open(
                                            "https://www.instagram.com/tagline360nyc/",
                                            "_blank"
                                        )
                                    }
                                    tabIndex="0"
                                >
                                    <div className="s-icon text-primary text-2xl self-center">
                                        <FaInstagram />
                                    </div>
                                </div>
                                <div
                                    className="s-item flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer"
                                    onClick={() =>
                                        window.open(
                                            "https://www.linkedin.com/company/tagline-360",
                                            "_blank"
                                        )
                                    }
                                    tabIndex="0"
                                >
                                    <div className="s-icon text-primary text-2xl self-center">
                                        <FaLinkedinIn />
                                    </div>
                                </div>
                            </div>
                            <div className="md:text-end text-center ">
                                <div className="md:text-md text-md mt-4 text-white">
                                    New York Office: 260 Madison Avenue, FL 8,
                                    NYC 10016 NY
                                </div>
                                <div className="md:text-md text-md text-white">
                                    Boston Office: 61 Colborne Rd. 6, Brighton
                                    02135 MA
                                </div>
                                <div className="md:text-md text-md text-white">
                                    California Office: 9245 Laguna Springs Dr.
                                    Suite 200 Elk Grove, 95758 CA
                                </div>
                                <div className="md:text-md text-md text-white">
                                    London Office: 160 City Road London EC1V 2NX
                                </div>
                                <div className="md:text-md text-md text-white">
                                    Dubai Office: Hor Al Anz Building, Office
                                    101-24, Hor Al Anz, Dubai UAE
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
