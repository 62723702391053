import React from 'react';

import Manifestitions from 'src/components/projects/Manifestitions';
import Bottom from 'src/components/Bottom';
import { ProjectPosts } from "src/components/posts/ProjectPosts";

function Projects() {

  return (
    <div className="max-w-[1920px] block mx-auto overflow-x-hidden">
      <div id="aboutUsPage" >
        <Manifestitions posts={ProjectPosts} hasPartners={true} />
      </div>
    </div>


  );
}

export default Projects;
